<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tableEmployees.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableEmployees.perPage"
        :current-page="tableEmployees.currentPage"
        :items="tableProvider"
        :fields="tableEmployees.fields"
        :sort-by.sync="tableEmployees.sortBy"
        :sort-desc.sync="tableEmployees.sortDesc"
        :sort-direction="tableEmployees.sortDirection"
        :filter="tableEmployees.filter"
        :filter-included-fields="tableEmployees.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableEmployees.currentPage * tableEmployees.perPage - tableEmployees.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              variant="primary"
              @click="onEdit(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableEmployees.perPage"
              :options="tableEmployees.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableEmployees.currentPage"
            :total-rows="tableEmployees.totalRows"
            :per-page="tableEmployees.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-employee-form"
      scrollable
      no-close-on-backdrop
      title="Edit Employee"
      aria-hidden="false"
      @ok="onValid"
    >
      <ValidationObserver
        ref="formEmployee"
      >
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="email"
            vid="email"
            rules="required|email|max:100"
          >
            <b-form-group>
              <label for="email">
                <strong>Email</strong>
              </label>
              <b-input
                id="email"
                v-model="employee.email"
                type="text"
                placeholder="enter email"
                autocomplete="off"
                disabled
                :state="errors.length > 0 ? false : null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="first name"
            vid="first_name"
            rules="required|max:50"
          >
            <b-form-group>
              <label for="first_name">
                <strong>First Name</strong>
              </label>
              <b-input
                id="first_name"
                v-model="employee.first_name"
                type="text"
                placeholder="enter first name"
                autocomplete="off"
                disabled
                :state="errors.length > 0 ? false : null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="middle name"
            vid="middle_name"
            rules=""
          >
            <b-form-group>
              <label for="middle_name">
                <strong>Middle Name</strong>
              </label>
              <b-input
                id="middle_name"
                v-model="employee.middle_name"
                type="text"
                placeholder="enter middle name"
                autocomplete="off"
                disabled
                :state="errors.length > 0 ? false : null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="last name"
            vid="last_name"
            rules="required|max:50"
          >
            <b-form-group>
              <label for="last_name">
                <strong>Last Name</strong>
              </label>
              <b-input
                id="last_name"
                v-model="employee.last_name"
                type="text"
                placeholder="enter last name"
                autocomplete="off"
                disabled
                :state="errors.length > 0 ? false : null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password"
            vid="password"
            rules="min:8|confirmed:password_confirmation"
          >
            <b-form-group
              description="Leave blank when not changing password"
            >
              <label for="password">
                <strong>Password</strong>
              </label>
              <b-input
                id="password"
                v-model="employee.password"
                type="password"
                placeholder="enter password"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password confirmation"
            vid="password_confirmation"
            rules="min:8"
          >
            <b-form-group>
              <label for="password_confirmation">
                <strong>Password Confirmation</strong>
              </label>
              <b-input
                id="password_confirmation"
                v-model="employee.password_confirmation"
                type="password"
                placeholder="enter password confirmation"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <div
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="employee.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              switch
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          Update Record
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SAdminEmployee } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'VAdminEmployees',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Employee Accounts'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false
      },
      employee: {
        id: 0,
        active: 0,
        email: '',
        password: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        branch_name: '',
        position_name: ''
      },
      tableEmployees: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          { key: 'action', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'email', sortable: true },
          { key: 'first_name', sortable: true },
          { key: 'middle_name', sortable: false },
          { key: 'last_name', sortable: true },
          { key: 'organization_name', sortable: false },
          { key: 'position_name', sortable: false },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      })

      return await SAdminEmployee.get(filters).then(
        ({ data }) => {
          this.tableEmployees.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tableEmployees.totalRows = 0
        return []
      })
    },

    onEdit (item) {
      this.state.editing = true
      this.employee.id = item.id
      this.employee.active = item.active
      this.employee.email = item.email
      this.employee.password = ''
      this.employee.password_confirmation = ''
      this.employee.first_name = item.first_name
      this.employee.middle_name = item.middle_name
      this.employee.last_name = item.last_name
      this.employee.branch_name = item.branch_name
      this.employee.grade_name = item.grade_name
      this.employee.position_name = item.position_name
      this.$bvModal.show('modal-employee-form')
    },

    async onValid (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formEmployee.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: this.state.editing ? 'Update Employee?' : 'Create Employee?',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Yes',
              cancelButtonColor: '#FF2929',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.state.busy = true
                return this.onPut()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! Your inputs seem to be incorrect or incomplete, please check and try again.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPut () {
      return new Promise(
        resolve => {
          SAdminEmployee.put(this.employee).then(
            ({ data }) => {
              this.$bvModal.hide('modal-employee-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                const row = _.find(this.$refs.table.localItems, {
                  id: this.employee.id
                })
                row.active = data.employee.active
                row.email = data.employee.email
                row.first_name = data.employee.first_name
                row.middle_name = data.employee.middle_name
                row.last_name = data.employee.last_name
                row.branch_name = data.employee.branch_name
                row.grade_name = data.employee.grade_name
                row.position_name = data.employee.position_name
                row.updated_at = data.employee.updated_at
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formEmployee.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
